<template>
  <validation-observer #default="{ handleSubmit }">
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <text-input
              id="username"
              name="Username"
              rules="required|"
              :text.sync="user.username"
            />
          </b-col>
          <b-col md="6">
            <text-input
              id="first-name"
              name="First Name"
              rules="required|"
              :text.sync="user.first_name"
            />
          </b-col>
          <b-col md="6">
            <text-input
              id="last-name"
              name="Last Name"
              rules="required|"
              :text.sync="user.last_name"
            />
          </b-col>
          <b-col md="6">
            <text-input
              id="email"
              name="Email"
              rules="required|email"
              :text.sync="user.email"
            />
          </b-col>
          <b-col md="6">
            <text-input
              id="password"
              name="Password"
              type="password"
              rules="required|password"
              :text.sync="user.password"
            />
          </b-col>
          <b-col md="6">
            <text-input
              id="password-confirmation"
              name="Password Confirmation"
              type="password"
              rules="required|password|confirmed:password"
              :text.sync="user.password_confirmation"
            />
          </b-col>
        </b-row>
        <div class="text-right">
          <back class="mr-2" />
          <submit-button
            v-bind="{submit,handleSubmit}"
          />
        </div>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import Back from '../common/FormInputs/Back.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import TextInput from '../common/FormInputs/TextInput.vue'

export default {
  name: 'UserForm',
  components: { TextInput, SubmitButton, Back },
  props: {
    user: { type: Object, default: () => ({}) },
    submit: { type: Function, default: () => {} },
  },
}
</script>

<style>

</style>
