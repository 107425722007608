<template>
  <b-container class="mt-5">
    <b-tabs v-model="tabIndex">
      <b-tab
        title="User"
        :disabled="tabIndex===1"
      >
        <user-form
          :user="user"
          :submit="addUser"
        />
      </b-tab>
      <b-tab
        title="Organization"
        :disabled="!user.access_token"
      >
        <organization-user-form
          :entity="entity"
          :submit="addEntity"
        >
          <b-col md="6">
            <text-input
              id="business-email"
              name="Business Email"
              rules="required|email"
              :text.sync="entity.business_email"
            />
          </b-col>
        </organization-user-form>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import UserEntity from '@/common/compositions/UserEntity/userEntityAPI'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import OrganizationUserForm from '@/common/components/UserEntities/OrganizationUserForm.vue'
import UserForm from '@/common/components/Users/UserForm.vue'
import auth from '@/common/compositions/Auth/authAPI'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'

export default {
  name: 'IndividualUserSignUp',
  components: { OrganizationUserForm, UserForm, TextInput },
  data() {
    return {
      tabIndex: 0,
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { entity, storeReclaimRequest } = UserEntity()
    const { user, register } = auth()
    return {
      entity, storeReclaimRequest, successfulOperationAlert, user, register,
    }
  },
  destroyed() {
    this.$store.commit('auth/setToken', null)
    localStorage.removeItem('accessToken')
  },
  methods: {
    addUser() {
      return this.register().then(res => {
        this.successfulOperationAlert('User is registered successfully')
        localStorage.setItem('accessToken', res.data.data.access_token)
        this.$store.commit('auth/setToken', res.data.data.access_token)
        this.$set(this.user, 'access_token', res.data.data.access_token)
        this.$nextTick(() => {
          this.tabIndex = 1
        })
      })
    },
    addEntity() {
      const claimedResource = JSON.parse(localStorage.getItem('claimedResource'))
      this.$set(this.entity, 'reclaim_type', claimedResource.reclaim_type)
      this.$set(this.entity, 'reclaim_id', claimedResource.reclaim_id)
      return this.storeReclaimRequest().then(() => {
        this.successfulOperationAlert('Entity is registered successfully')
        this.$router.push({
          name: 'login',
        })
      })
    },
  },
}
</script>

<style>

</style>

IndividualUserForm
